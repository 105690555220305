<template>
    <el-form-item label="">

        <el-collapse
            class="b-card"
            v-model="activeNames"
        >
            <el-collapse-item v-for="(item,index) in formModel.where"
                :title="item.where_name"
                :name="item.id"
                :key="index"
            >
                <el-checkbox-group @change="toggle" v-model="item.checkList">
                    <el-checkbox v-for="(a,i) in item.child"
                    :key="i"
                    :label="a"
                    >
                    {{a.where_name}}
                    </el-checkbox>
                </el-checkbox-group>
                <!-- <el-button style="margin-top:20px;" type="primary" size="medium" @click="Add(item)">添加</el-button> -->
            </el-collapse-item>
        </el-collapse>
    </el-form-item>

</template>

<script>
export default {
  name: 'SendWhere_',
  props: { formModel: {} },
  data () {
    return {
      activeNames: []
    }
  },
  async mounted () {
    let _this = this
    setTimeout(() => {
      _this.formModel.where.forEach((element, index) => {
        if (element.checkList.length > 0) {
          _this.activeNames.push(String(index + 1))
        }
      })
    }, 100)
  },
  methods: {
    toggle (item) {
      // let _item = Object.assign({}, item)
      this.$emit('toggle', item)
    }
  }
}
</script>
<style lang='' scoped>
</style>
