<template>
  <div>
    <el-form-item label="发送方式">
      <el-checkbox-group v-model="formModel.send_type">
        <el-checkbox label="1">服务通知</el-checkbox>
        <el-checkbox label="2">服务号</el-checkbox>
        <el-checkbox label="3">短信</el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <el-form-item label="服务通知模板"
     v-if="formModel.send_type.includes('1')">

      <el-select
        style="width:80%"
        v-model="formModel.MINI_TEMP_ID"
        placeholder="请选择服务模板"
        @change="handleChange(formModel)"
      >
        <el-option
          v-for="temp in formModel.tempList"
          :key="temp.template_id"
          :label="temp.title"
          :value="temp.template_id"
        >
        </el-option>
      </el-select>
      <div
        class="tempBox"
        v-if="formModel.MINI_TEMP_BODY"
      >
        <p
          v-for="select in formModel.MINI_TEMP_BODY.example"
          :key="select"
        >
          {{select}}</p>
      </div>
    </el-form-item>

    <el-form-item label="服务号模板"
    v-if="formModel.send_type.includes('2')">
      <el-select
        style="width:60%"
        v-model="formModel.select_service_number_id"
        placeholder="请选择模板"
      >
        <el-option
          v-for="item in formModel.service_number"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="短信模板"
    v-if="formModel.send_type.includes('3')">
      <el-select
        style="width:50%"
        v-model="formModel.SMS_ID"
        placeholder="请选择模板"
         @change="handleChangeSMS(formModel)"
      >
        <el-option
          v-for="item in formModel.smsList"
          :key="item.tempKey"
          :label="item.title"
          :value="item.tempKey"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'sendMsg',
  props: { formModel: {} },

  methods: {
    handleChange (item) {
      this.formModel.tempList.forEach(element => {
        if (element.template_id === item.MINI_TEMP_ID) {
          this.formModel.MINI_TEMP_BODY = element
        }
      })
    },
    handleChangeSMS (item) {
      this.formModel.smsList.forEach(element => {
        if (element.tempKey === item.SMS_ID) {
          this.formModel.SMS_BODY = element
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.tempBox {
    width: 80%;
    margin-top: 20px;
    background: #f6f6f6;
    padding: 20px;
    p {
        line-height: 0.7em;
    }
}
</style>
