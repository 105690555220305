<template>
  <div>
      <el-form-item label="开始发放时间">
            <div class="b-card">
             <el-radio-group v-model="formModel.SST">
                <el-radio :label="1">指定日期</el-radio>
                <el-date-picker
                v-model="formModel.SSB"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
                <el-radio :label="2" style="margin-left:50px;">动态日期</el-radio>
                  <el-select v-model="formModel.SSB2" placeholder="请选择">
                    <el-option
                    v-for="item in selectTime"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <el-select style="width:100px;margin-left:20px;" v-model="formModel.SSB2S" placeholder="请选择">
                    <el-option
                    v-for="item in selectTimeDay"
                    :key="item"
                    :label="item ==0 ?'当天':((formModel.SSB2 == 1?'前':'后') + item  + '天')"
                    :value="item">
                    </el-option>
                </el-select>

            </el-radio-group>
            </div>
        </el-form-item>

        <el-form-item label="结束发放日期">
                <el-date-picker
                v-model="formModel.SET"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
        </el-form-item>

        <el-form-item label="发放数量">
            <el-input type="number" :min="0" style="width:300px;" v-model="formModel.send_sum" placeholder=""></el-input>  张
        </el-form-item>
        <el-form-item label="每人限领">
            <el-input type="number" :min="1" style="width:300px;" v-model="formModel.send_user_sum" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="重复周期">
            <el-radio-group v-model="formModel.send_cycle">
                <el-radio :label="1" v-if="formModel.SST == 1 || formModel.SST == 2">不重复</el-radio>
                <el-radio :label="2" v-if="formModel.SST == 1 || formModel.SST == 2">每年</el-radio>
                <el-radio :label="3" v-if="formModel.SST == 1">每月</el-radio>
                <el-radio :label="4" v-if="formModel.SST == 1">每周</el-radio>
            </el-radio-group>
        </el-form-item>
         <el-form-item label="劵生效日期">
              <div class="b-card">
             <el-radio-group v-model="formModel.coupon_start_time_type">
                <el-radio :label="1">指定日期</el-radio>
                <el-date-picker
                v-model="formModel.coupon_start_time_body"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
                <el-radio :label="2" style="margin-left:50px;">动态日期</el-radio>
                <el-select v-model="formModel.coupon_start_time_body_two" placeholder="请选择">
                    <el-option
                    v-for="item in selectTime"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
                <el-select
                 style="width:100px;margin-left:20px;" v-model="formModel.coupon_start_time_body_two_2" placeholder="请选择">
                    <el-option
                    v-for="item in selectTimeDay"
                    :key="item"
                    :label="item ==0 ?'当天':((formModel.coupon_start_time_body_two == 1?'前':'后') + item  + '天')"
                    :value="item">
                    </el-option>
                </el-select>

            </el-radio-group>
            </div>
        </el-form-item>
        <el-form-item label="劵失效日期">
            <div class="b-card">
             <el-radio-group v-model="formModel.coupon_end_time_type">
                <el-radio :label="1">指定日期</el-radio>
                <el-date-picker
                v-model="formModel.coupon_end_time_body"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd">
                </el-date-picker>
                <el-radio :label="2" style="margin-left:50px;">有效期</el-radio>
                <el-input style="width:200px;margin-left:20px;" v-model="formModel.coupon_end_time_body2" placeholder="请输入有效天数"></el-input>天

            </el-radio-group>
            </div>
        </el-form-item>
  </div>
</template>

<script>

export default {
  name: 'sendTimeTemp',
  props: { formModel: {} },
  data () {
    return {
      selectTime: [
        {
          id: 1,
          name: '会员生日'
        },
        {
          id: 2,
          name: '注册日期'
        }
      ],
      selectTimeDay: [
        0, 1, 3, 5, 7, 15, 30
      ]
    }
  }
}

</script>
<style lang='' scoped>

</style>
