<template>
    <div class="b-card" v-if="selectArr.length>0">
        <div
            v-for="(a,i) in selectArr"
            :key="i">
            <el-form-item
                class="b-card sws"
                :label="a.where_name"
            >
                <!-- 会员等级 -->
                <div v-if="a.id == 6">
                    <el-checkbox-group v-model="a.body.where_body.type">
                        <el-checkbox
                            v-for="(c,c_i) in formModel.members"
                            :key="c_i"
                            :label="c.id"
                        >
                            {{c.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <!-- 会员性别 -->
                <div v-if="a.id == 7">
                    <el-checkbox-group v-model="a.body.where_body.type">
                        <el-checkbox :label="1">男</el-checkbox>
                        <el-checkbox :label="2">女</el-checkbox>
                        <el-checkbox :label="3">未知</el-checkbox>
                    </el-checkbox-group>
                </div>
                <!-- 会员年龄 -->
                <div v-if="a.id ==8">
                    <el-input
                        style="width:100px"
                        v-model="a.body.where_body.min"
                        placeholder=""
                    ></el-input> -
                    <el-input
                        style="width:100px"
                        v-model="a.body.where_body.max"
                        placeholder=""
                    ></el-input> 岁
                </div>
                <!-- 会员标签 -->
                <div v-if="a.id ==9">
                    暂无开放
                    <!-- <el-input style="width:100px" v-model="a.body.where_body.min" placeholder=""></el-input> - -->
                    <!-- <el-input style="width:100px" v-model="a.body.where_body.max" placeholder=""></el-input> 岁 -->
                </div>
                <!-- 临近升级 -->
                <div v-if="a.id == 10">
                    <el-checkbox-group v-model="a.body.where_body.type">
                        <el-checkbox
                            v-for="(c,c_i) in formModel.members"
                            :key="c_i"
                            :label="c.id"
                        >
                            {{c.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                    <el-input type="number" :min="0" style="width:300px;" v-model="a.body.where_body.value" placeholder="例如:300(差300积分以内的所有用户)"></el-input>
                    积分
                </div>
                <!-- 会员生日距离 -->
                <div v-if="a.id == 11">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">本周</el-radio>
                        <el-radio :label="2">下周</el-radio>
                        <el-radio :label="3">本月</el-radio>
                        <el-radio :label="4">下月</el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>

                    </el-radio-group>

                </div>
                <!-- 注册时间 -->
                <div v-if="a.id == 12">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">本周</el-radio>
                        <el-radio :label="2">本月</el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                </div>
                <!-- 累积消费金额 -->
                <div v-if="a.id == 13">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        消费金额区间
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        元
                    </div>
                </div>
                <!-- 平均消费金额 -->
                <div v-if="a.id == 14">
                    <el-radio-group v-model="a.body.where_body.type">
                         <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        消费金额区间
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        元
                    </div>
                </div>
                <!-- 累积消费次数 -->
                <div v-if="a.id == 15">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        消费次数区间
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        次
                    </div>
                </div>
                <!-- 未消费时长 -->
                <div v-if="a.id == 16">
                    <div>
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        天
                    </div>
                    <div>
                        <el-checkbox v-model="a.body.where_body.isG">筛选结果包含从未消费过的会员</el-checkbox>
                    </div>
                </div>
                <!-- 按商品喜好  -->
                <!-- 消费过的商品 -->
                <div v-if="a.id == 17">
                    <el-radio-group v-model="a.body.where_body.type">
                         <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        选择商品
                        <el-select
                            v-model="a.body.where_body.body"
                            multiple
    collapse-tags
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in formModel.shops"
                                :key="item.id"
                                :label="item.product_name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 消费过的品类 -->
                <div v-if="a.id == 18">
                    <el-radio-group v-model="a.body.where_body.type">
                       <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        选择品类
                        <el-select
                            v-model="a.body.where_body.body"
                            multiple
    collapse-tags
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in formModel.classify"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 未消费过的商品 -->
                <div v-if="a.id == 19">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        选择商品
                        <el-select
                            v-model="a.body.where_body.body"
                            placeholder="请选择"
                            multiple
    collapse-tags
                        >
                            <el-option
                                v-for="item in formModel.shops"
                                :key="item.id"
                                :label="item.product_name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 未消费过的品类 -->
                <div v-if="a.id == 20">
                    <el-radio-group v-model="a.body.where_body.type">
                         <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        选择品类
                        <el-select
                            multiple
    collapse-tags
                            v-model="a.body.where_body.body"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in formModel.classify"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 按会员账户 -->
                <!-- 累次充值金额 -->
                <div v-if="a.id == 21">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        充值金额区间
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        元
                    </div>
                </div>
                <!-- 累次充值次数 -->
                <div v-if="a.id == 22">
                    <el-radio-group v-model="a.body.where_body.type">
                         <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        充值次数区间
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        次
                    </div>
                </div>
                <!-- 会员余额 -->
                <div v-if="a.id == 23">
                    <el-input
                        style="width:100px;"
                        v-model="a.body.where_body.min"
                        placeholder=""
                    ></el-input>
                    -
                    <el-input
                        style="width:100px;"
                        v-model="a.body.where_body.max"
                        placeholder=""
                    ></el-input>
                    元
                </div>
                <!-- 会员积分 -->
                <div v-if="a.id == 24">
                    <el-input
                        style="width:100px;"
                        v-model="a.body.where_body.min"
                        placeholder=""
                    ></el-input>
                    -
                    <el-input
                        style="width:100px;"
                        v-model="a.body.where_body.max"
                        placeholder=""
                    ></el-input>
                    分
                </div>
                <!-- 按劵购销 -->
                <!-- 优惠券使用数量 -->
                <div v-if="a.id == 25">
                    <el-radio-group v-model="a.body.where_body.type">
                        <el-radio :label="1">近期
                            <el-input
                            style="width:200px;"
                            v-model="a.body.sum" placeholder="近期多少天"></el-input>天
                        </el-radio>
                        <el-radio :label="9">自定义</el-radio>
                        <el-date-picker
                            v-model="a.body.where_body.time"
                            type="daterange"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-radio-group>
                    <div class="m-t10">
                        劵使用量
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.min"
                            placeholder=""
                        ></el-input>
                        -
                        <el-input
                            style="width:100px;"
                            v-model="a.body.where_body.max"
                            placeholder=""
                        ></el-input>
                        张
                    </div>
                </div>
                <!-- 优惠剩余量 -->
                <div v-if="a.id == 26">
                    <el-input
                        style="width:100px;"
                        v-model="a.body.where_body.min"
                        placeholder=""
                    ></el-input>
                    -
                    <el-input
                        style="width:100px;"
                        v-model="a.body.where_body.max"
                        placeholder=""
                    ></el-input>
                    张
                </div>
                <!-- 消费过的优惠劵 -->
                <div v-if="a.id == 27">
                   <el-select
                            multiple
    collapse-tags
                            v-model="a.body.where_body.body"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in formModel.coupons"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                </div>
                <!-- 持有的优惠劵 -->
                <div v-if="a.id == 28">
                    <el-select
                            multiple
    collapse-tags
                            v-model="a.body.where_body.body"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in formModel.coupons"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                </div>

                <el-button
                    @click="del(a)"
                    class="delS"
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                ></el-button>
            </el-form-item>
        </div>
        <!-- 搜索按钮 -->
        <el-form-item label="">
             <el-button
                type="primary"
                @click="searchGo"
            >查询</el-button>
            <div v-if="searchOk">
            已筛选出 {{searchSum.length}} 位会员
            </div>
        </el-form-item>
    </div>
</template>

<script>

import CouponService from '@admin/services/CouponService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'sendWhereSelect',
  props: { formModel: {} },
  data () {
    return {
      selectArr: [],
      searchOk: false,
      searchSum: []
    }
  },
  mounted () {
    this.selectArr = Object.assign(this.formModel.send_where)
    for (let i = 0; i < this.formModel.where.length; i++) {
      for (let j = 0; j < this.selectArr.length; j++) {
        for (let a = 0; a < this.formModel.where[i].child.length; a++) {
          if (this.formModel.where[i].child[a].id === this.selectArr[j].id) {
            this.formModel.where[i].child[a] = this.selectArr[j]
            this.formModel.where[i].checkList.push(this.selectArr[j])
            break
          }
        }
      }
    }
  },

  methods: {
    del (a) {
      let i = this.selectArr.indexOf(a)
      let index
      this.selectArr.splice(i, 1)
      this.formModel.where.forEach(element => {
        index = element.checkList.indexOf(a)
        if (index !== -1) {
          element.checkList.splice(index, 1)
        }
      })
    },
    async searchGo () {
      let param = this.selectArr
      const { data } = await flatry(CouponService.sendSearch(param))
      if (data) {
        this.searchOk = true
        this.searchSum = data.data
        this.formModel.send_where = param
        this.formModel.accord = data.data
        this.formModel.send_sum = data.data.length
      }
    },
    toggle () {
      let arr = []
      this.formModel.where.forEach(element => {
        if (element.checkList.length > 0) {
          element.checkList.forEach(i => {
            arr.push(i)
          })
        }
      })
      this.selectArr = arr
      this.formModel.send_where = this.selectArr
    }
  }
}
</script>
<style lang='scss' scoped>
.m-t10{
    margin-top: 10px;
}
.sws {
    margin-bottom: 20px;
}
.delS {
    position: absolute;
    right: 0;
    top: 0;
}
</style>
