<template>
    <el-form
        class="box-form"
        v-if="formModel"
        :rules="formRules"
        :model="formModel"
        ref="formModel"
        label-width="140px"
        label-suffix="："
        @submit.native.stop.prevent="handleFormSubmit('formModel')"
    >
        <el-form-item label="选择优惠券">
            <el-select v-model="formModel.coupon_id" placeholder="请选择">
                <el-option
                v-for="item in formModel.coupons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="营销活动名">
            <el-input
            style="width:300px;" v-model="formModel.send_name" placeholder="请输入营销活动名"></el-input>

        </el-form-item>

        <h4>添加筛选条件</h4>
        <sendWhere @toggle="toggle" :formModel="formModel"/>

        <h4>已添加得筛选条件</h4>
        <sendWhereS  ref="whereBody" :formModel="formModel"/>

        <h4>设定发放时间</h4>
        <sendTime :formModel="formModel"/>
        <h4>设定消息通知</h4>
        <sendMsg :formModel="formModel"/>

        <!-- 确定吧 -->
        <el-form-item>
        <el-button
          size="medium"
          type="primary"
          native-type="submit"
          :loading="submitLoading"
        >{{ submitText }}</el-button>
      </el-form-item>
    </el-form>
</template>

<script>
import sendTime from './SendTime'
import sendWhere from './SendWhere'
import sendWhereS from './SendWhereSelect'
import sendMsg from './SendMsg'

export default {
  name: 'SendCouponForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formRules: null,
      formModel: null
    }
  },

  components: { sendTime, sendWhere, sendWhereS, sendMsg },

  mounted () {
    this.formModel = Object.assign(this.model)
  },

  methods: {
    toggle (a) {
      this.$refs.whereBody.toggle(a)
    },
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true
        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  },

  watch: {}
}
</script>
<style lang='scss' scoped>
.box-form{
    h4{
        padding:50px 0 10px 0;
        margin-left: 140px;
    }
}
</style>
