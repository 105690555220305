import Vue from 'vue'

const CouponService = {
  all (query) {
    return Vue.http.get('coupon/index', query)
  },

  delete (id) {
    return Vue.http.delete('coupon/delete', id)
  },

  create (store = null) {
    const endpoint = 'coupon/create'

    if (store === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, store)
  },

  edit (id, model = null) {
    if (model === null) {
      return Vue.http.get('coupon/update', { id: id })
    }

    return Vue.http.put('coupon/update', model, { id: id })
  },

  // 发劵api
  allSend (query) {
    return Vue.http.get('coupon/send-index', query)
  },

  // 发劵编辑
  sendEdit (id, model = null) {
    if (model === null) {
      return Vue.http.get('coupon/send-view', { id: id })
    }

    return Vue.http.put('coupon/send-view', model, { id: id })
  },
  // 发劵搜索
  sendSearch (param) {
    return Vue.http.post('coupon/search', param)
  },
  // 发劵删除
  sendDelete (id) {
    return Vue.http.delete('coupon/send-delete', id)
  },
  // 发劵创建
  sendCreate (model = null) {
    const endpoint = 'coupon/send-create'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  },
  // 兑换码创建
  postCode (id, model = null) {
    const endpoint = 'coupon/post-code'
    if (model === null) {
      return Vue.http.get(endpoint, { id: id })
    }

    return Vue.http.post(endpoint, model, { id: id })
  },
  // 更改运行状态
  toggle (id, model = null) {
    return Vue.http.put('coupon/send-view', model, { id: id })
  },
  // 获取发劵统计信息
  getSendListCount (param) {
    return Vue.http.get('coupon/send-list', param)
  }
}

export default CouponService
